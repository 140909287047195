.app {
    background-image:-webkit-gradient(linear,left top,left bottom,from(#1a357c),to(#5a3e8c));
    height: 100vh;
    overflow: auto;
}

.contents {
    height: 100%;
    padding-top: 60px;
    padding-bottom: 10px;
    box-sizing: border-box;
    overflow: auto;
}
