.signup {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

* {
    box-sizing: border-box;
}

.form-card {
    width: 480px;
    max-width: 100%;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 25%) 0 0.25rem 0.25rem;
    border: 1px solid rgba(91, 105, 135, 0.22);
    border-radius: 3px;
    padding: 24px;
}

.form-element {
    margin: 15px 0;
}

label {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 5px;
}

input {
    height: 42px;
    width: 100%;
    background: #fff;
    border: 1px solid #A8B2BD;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 8px 12px;
}

input.error {
    border-color: #D41D34;
    color: #D41D34;
    padding-right: 35px;
}

.error-text {
    color: #D41D34;
    padding: 0;
    margin: 0;
}

button {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: calc(100% - 10px);
    height: 48px;
    border-radius: 4px;
    padding: 2px 15px;
    box-sizing: border-box;
    margin: 10px 0;
    background: #1550C1;
    color: #fff;
    border: 1px solid #1550C1;
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background-color: #6698F8;
    border-color: #6698F8;
}
