.header {
    position: fixed;
    background-color: #fff;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
    box-shadow: rgb(0 0 0 / 25%) 0px 2px 3px;
}

.title {
    font-size: 18px;
}
